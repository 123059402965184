import React from "react";
import Clients from "./clients";

const About = () => {
    return (
        <section className="scrollspy align-items-center no-margin" id="about">
            <div className="container">
                <div
                    className="row no-margin align-items-center"
                    style={{ minHeight: "96vh" }}
                >
                    <div className="col-12 col-md-6 about-text">
                        <h1 className="section-title">O nama</h1>
                        <p>
                            U Atamagaiju se trudimo da kreiramo inteligentna
                            softverska rešenja osmišljena tako da na
                            najefikasniji način odgovore na svakodnevne izazove
                            dinamičnih poslovnih okruženja.
                        </p>
                        <p>
                            To činimo korišćenjem modernih tehnologija za web i
                            mobilne platforme, uz kontinualno usavršavanje i
                            optimizaciju svih komponenti sistema koje smo
                            razvili.
                        </p>
                        <p>
                            Naš fokus je na proizvodima i servisima namenjenim
                            digitalnom dobu pametnih uređaja, pre svega alatima
                            za unapređenje poslovanja. Rezultat su softverska
                            rešenja koja su jednostavna za upotrebu, atraktivna
                            i prilagođena navikama korisnika, ali istovremeno
                            izuzetno pouzdana, sigurna i spremna da odgovore na
                            sve buduće zahteve razvoja kompanija naših
                            korisnika.
                        </p>
                        <p>
                            Zbog toga smo razvoj softvera bazirali na
                            kombinaciji savremenih HTML5 tehnologija i zrelih
                            rešenja za mehanizme poslovne logike i pohranjivanja
                            podatka kao što su Microsoft SQL Server i .Net
                            platforma.
                        </p>
                    </div>
                    <div className="col-12 col-md-6">
                        <Clients />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
