import React from "react";
import { Button } from "react-materialize";
import Solidus from "../../assets/solidus.svg";

const Services = () => {
    return (
        <section
            className="scrollspy align-items-center no-margin"
            id="products"
        >
            <div className="container-fluid no-margin">
                <div
                    className="row no-margin align-items-center"
                    style={{ minHeight: "96vh" }}
                >
                    <div className="col">
                        <div className="container">
                            {/* <h1 className="section-title underlined cyan-text text-darken-0 z-depth-0">
                                Solidus platforma
                            </h1> */}
                            <div>
                                <Solidus
                                    style={{
                                        maxHeight: "60px",
                                        marginBottom: "10px",
                                    }}
                                />
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 solidus-text">
                                    <p>
                                        Solidus je napredna modularna softverska
                                        platforma za razvoj multifunkcionalnih
                                        rešenja. Sastoji se od modula,
                                        komponenti i mehanizama koji se
                                        jednostavno mogu kombinovati na
                                        različite načine i tako prilagoditi
                                        širokom spektru poslovnih potreba.
                                    </p>
                                    <p>
                                        Standardni moduli Solidus platforme
                                        sadrže sve osnovne funkcije za
                                        planiranje resursa preduzeća, a moguće
                                        je i brzo prilagođavanje i razvoj novih
                                        modula. Pri tome je finalno rešenje
                                        implementirano kao cloud servis (Solidus
                                        ERP) ili na infrastrukturi klijenta.
                                    </p>
                                    <p>
                                        Modularnost Solidus rešenja omogućuje
                                        vam da izabrane komponente prilagodite
                                        zahtevima posla i na taj način slobodno
                                        upravljate troškovima, uz uključivanje
                                        novih funkcionalnosti kada se pojavi
                                        potreba. Uz to smo predvideli i
                                        povezivanje sa postojećim sistemima i
                                        drugim softverskim rešenjima, pa će vaši
                                        podaci ostati dostupni.
                                    </p>
                                </div>
                                <div className="col-12 col-md-6">
                                    <h2
                                        className="section-subtitle cyan-text"
                                        style={{ marginTop: "15px" }}
                                    >
                                        Solidus ERP
                                    </h2>
                                    <p>
                                        Solidus ERP je jedna od implementacija
                                        Solidus platforme za razvoj softvera.
                                        Predstavlja kompletan poslovni
                                        informacioni sistem za mala i srednja
                                        preduzeća u formi modularnog cloud
                                        servisa. Koncipiran je tako da se lako
                                        prilagođava i brzo proširuje kako bi
                                        izdržao test vremena i odgovorio na sve
                                        trenutne i buduće potrebe vašeg biznisa.
                                    </p>
                                    <p>
                                        Budući da je Solidus sistem baziran na
                                        savremenim internet tehnologijama i
                                        fleksibilnoj arhitekturi, dostupan je u
                                        svakom trenutku i sa bilo kog računara,
                                        uključujući i verzije za mobilne uređaje
                                        da bi najvažnije informacije bile uvek
                                        na dohvat ruke. Pri tome je posebna
                                        pažnja posvećena sigurnosti podataka,
                                        performansama i optimalnom iskorišćenju
                                        serverskih i klijentskih resursa.
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col center">
                                    <Button
                                        className="cyan darken-1"
                                        href="http://www.thesolidus.com/"
                                        node="a"
                                        waves="light"
                                    >
                                        Saznajte više
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;
