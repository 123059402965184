import React from "react";
import { Button } from "react-materialize";
import UxDesign from "../../assets/uxdesign2.svg";
import ProductDev from "../../assets/productdev2.svg";
import Consuting from "../../assets/services2.svg";

const Services = () => {
    return (
        <section
            className="scrollspy align-items-center no-margin"
            id="services"
        >
            <div className="container-fluid no-margin">
                <div
                    className="row no-margin align-items-center"
                    style={{ minHeight: "96vh" }}
                >
                    <div className="col">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h1 className="section-title underlined deep-purple-text text-lighten-2 z-depth-0">
                                        Usluge i projekti
                                    </h1>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-4">
                                                <ProductDev
                                                    style={{
                                                        maxHeight: "130px",
                                                        marginBottom: "20px",
                                                    }}
                                                />
                                                <h2 className="section-subtitle deep-purple-text text-lighten-3">
                                                    Razvoj proizvoda
                                                </h2>
                                                <p>
                                                    Spremni smo da uložimo naše
                                                    ogromno iskustvo, znanje,
                                                    veštine i kreativnost kako
                                                    bismo vam pomogli da
                                                    osmislite i isplanirate
                                                    izuzetne softverske koncepte
                                                    i proizvode.
                                                </p>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <UxDesign
                                                    style={{
                                                        maxHeight: "130px",
                                                        marginBottom: "20px",
                                                    }}
                                                />
                                                <h2 className="section-subtitle deep-purple-text text-lighten-3">
                                                    UX/Interaction dizajn
                                                </h2>
                                                <p>
                                                    Dozvolite nam da se
                                                    pobrinemo da vaša softverska
                                                    rešenja zadovolje sve
                                                    savremene kriterijume,
                                                    uključujući korisničko
                                                    iskustvo, vizuelni dizajn i
                                                    arhitekturu informacija.
                                                </p>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <Consuting
                                                    style={{
                                                        maxHeight: "130px",
                                                        marginBottom: "20px",
                                                    }}
                                                />
                                                <h2 className="section-subtitle deep-purple-text text-lighten-3">
                                                    Konsalting
                                                </h2>
                                                <p>
                                                    Stojimo vam na raspolaganju
                                                    da svojim iskustvom, znanjem
                                                    i veštinama povežemo
                                                    tehnologiju i poslovanje,
                                                    ojačamo vaše timove i rešimo
                                                    sve izazove u razvoju
                                                    softvera.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col center">
                                                <Button
                                                    className="deep-purple lighten-1"
                                                    href="mailto:office@atamagai.com"
                                                    node="a"
                                                    waves="light"
                                                    style={{marginTop: "20px"}}
                                                >
                                                    Kontaktirajte nas
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;
