import React from "react";
import Customs from "../../assets/carina.svg";
import Pks from "../../assets/pks.svg";
import Dhl from "../../assets/dhl.svg";
import Bosch from "../../assets/bosch.svg";
import Fedex from "../../assets/fedex.svg";
import Bayer from "../../assets/bayer.svg";

const Clients = () => {
    return (
        <div className="container">
            <h5 className="white-text" style={{ marginBottom: "30px" }}>
                Poverenje su nam, između ostalih, ukazali
            </h5>
            <div className="row">
                <div className="col-sm">
                    <Customs style={{ maxWidth: "150px" }} />
                </div>
                <div className="col-sm">
                    <Pks style={{ maxWidth: "150px" }} />
                </div>
                <div className="col-sm">
                    <Dhl style={{ maxWidth: "150px" }} />
                </div>
                <div className="w-100"></div>
                <div className="col-sm">
                    <Bosch style={{ maxWidth: "150px" }} />
                </div>
                <div className="col-sm">
                    <Fedex style={{ maxWidth: "150px" }} />
                </div>
                <div className="col-sm">
                    <Bayer style={{ maxWidth: "150px" }} />
                </div>
            </div>
        </div>
    );
};

export default Clients;
