import React, { Component } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import M from "materialize-css";
import Intro from "../components/home/intro";
import About from "../components/home/about";
import Services from "../components/home/services";
import Products from "../components/home/products";

class IndexPage extends Component {
    componentDidMount() {
        var elems = document.querySelectorAll(".scrollspy");
        M.ScrollSpy.init(elems, {
            scrollOffset: 64,
            activeClass: "active",
            throttle: 200,
        });
    }

    render() {
        return (
            <Layout isHome={true}>
                <SEO title="Home" />

                <Intro />

                <About />

                <Products />

                <Services />

                <section
                    className="home-descr scrollspy no-padding hide-on-small-only"
                    id="homeDescr"
                ></section>
            </Layout>
        );
    }
}

export default IndexPage;
