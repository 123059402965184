import React, { useEffect } from "react";
import Professionals from "../../assets/professionals.svg";
import { CardPanel } from "react-materialize";
import ScrollMagic from "scrollmagic";

const Intro = () => {
    useEffect(() => {
        let introHeight = document.getElementById("homeIntro").offsetHeight;
        let introContentHeight = document.getElementById("homeIntroContent")
            .offsetHeight;
        let controller = new ScrollMagic.Controller();
        new ScrollMagic.Scene({
            duration: introHeight - introContentHeight - 10,
            offset: 0,
        })
            .setPin("#homeIntroContent")
            .addTo(controller);
    }, []);
    return (
        <section className="home-intro no-padding" id="homeIntro">
            <div className="container">
                <div className="row" id="homeIntroContent">
                    <div className="col-9">
                        <Professionals
                            className="hide-on-small-only"
                            style={{
                                width: "500px",
                                height: "80px",
                                marginTop: "100px",
                                marginLeft: "0px",
                            }}
                        />
                        <CardPanel className="atab darken-2">
                            <span className="white-text">
                                <h5 style={{ margin: "0px", fontWeight: "400" }}>
                                    Inovativna poslovna softverska rešenja za
                                    digitalno doba povezanih uređaja i interneta
                                </h5>
                            </span>
                        </CardPanel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Intro;
